import Cookies from 'js-cookie'

const TokenKey = 'Gblog-Token'
const AuthTypeKey = 'Gblog-authType'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  Cookies.remove(getToken())
  return Cookies.remove(TokenKey)
}

export function setAuthType(type) {
  return Cookies.set(AuthTypeKey,type)
}
export function getAuthType() {
  return Cookies.get(AuthTypeKey)
}

export function setUser(user) {
  return window.localStorage.setItem(getToken(), JSON.stringify(user))
  // return Cookies.set(Cookies.get(TokenKey),JSON.stringify(user))
}

export function getUser() {
  const token = getToken();
  if (token && window.localStorage.getItem(token)){
    return JSON.parse(window.localStorage.getItem(token))
  }
  return ''
}



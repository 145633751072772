import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/style.less'
import './assets/font/iconfont.css'
import Message from './components/message/index.js';
import Layer from './components/layer/index.js';
import * as filters from './filters'

import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css'	//样式


Vue.directive('highlight',function (el) {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block)=>{
    hljs.highlightBlock(block)
  })
})

Vue.config.productionTip = false
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.prototype.$message = Message;
Vue.prototype.$layer= Layer;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

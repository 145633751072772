import Vue from 'vue';
import Main from './main.vue';
let LayerConstructor = Vue.extend(Main);

let instance;
const detailsOptions = {
    type: 1,
    title: '登录',
    width: 400,
    height: 300,
    onClose: null
}

const Layer = function(options) {
    options = options || {}
    options = Object.assign(detailsOptions,options)

    // options.onClose = function() {
    //     Layer.close(id, userOnClose);
    // };
    instance = new LayerConstructor({
        data: options
    });
    instance.$mount();
    document.body.appendChild(instance.$el);
    instance.show = true;
    return instance;
};

Layer.close = function(id, userOnClose) {
    let len = instances.length;
    let index = -1;
    let removedHeight;
    for (let i = 0; i < len; i++) {
        if (id === instances[i].id) {
            removedHeight = instances[i].$el.offsetHeight;
            index = i;
            if (typeof userOnClose === 'function') {
                userOnClose(instances[i]);
            }
            instances.splice(i, 1);
            break;
        }
    }
    if (len <= 1 || index === -1 || index > instances.length - 1) return;
    for (let i = index; i < len - 1 ; i++) {
        let dom = instances[i].$el;
        dom.style['top'] =
            parseInt(dom.style['top'], 10) - removedHeight - 16 + 'px';
    }
};

export default Layer;

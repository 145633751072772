import request from '@/utils/request'

export function fetchList(params) {
    return request({
        url: '/api/posts',
        method: 'get',
        params
    })
}

export function fetchFocus() {
    return request({
        url: '/api/focus',
        method: 'get'
    })
}

export function fetchCategory() {
    return request({
        url: '/api/category',
        method: 'get'
    })
}

export function fetchCategoryPost(category) {
    return request({
        url: `/api/category/${category}`,
        method: 'get'
    })
}
export function searchPost(word) {
    return request({
        url: `/api/search/${word}`,
        method: 'get'
    })
}
export function fetchFriend() {
    return request({
        url: '/api/friend',
        method: 'get'
    })
}

export function fetchSocial() {
    return request({
        url: '/api/social',
        method: 'get'
    });
}

export function fetchSiteInfo() {
    return request({
        url: '/api/site',
        method: 'get'
    })
}

export function fetchComment(postId) {
    return request({
        url: `/api/postComment/${postId}`,
        method: 'get'
    })
}

export function fetchPost(id) {
    return request({
        url: `/api/post/${id}`,
        method: 'get'
    })
}

export function contactMe(data) {
    return request({
        url: '/api/contactMe',
        method: 'post',
        data: data
    })
}

export function check() {
    return request({
        url: '/api/c',
        method: 'get'
    })
}

export function auth(query) {
    return request({
        url: `/auth/${query.type}/callback`,
        method: 'get',
        params: query
    })
}
export function thirdAuth(type) {
    return request({
        url: `/auth/${type}`,
        method: 'get'
    })
}

export function logout() {
    return request({
        url: '/admin/user/logout',
        method: 'post'
    })
}

export function sendMailCode(mail) {
    return request({
        url: '/api/mailVerify',
        method: 'post',
        data: {mail: mail}
    })
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: { title: '首页'}
    },
    {
        path: '/category/:cate',
        name: 'category',
        component: () => import('../views/Home.vue'),
        meta: { title: '分类', params: 'cate'}
    },
    {
        path: '/search/:words',
        name: 'search',
        component: () => import('../views/Home.vue'),
        meta: { title: '搜索', params: 'words'}
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue'),
        meta: { title: '关于'}
    },
    {
        path: '/friend',
        name: 'friend',
        component: () => import('../views/Friend.vue'),
        meta: { title: '友链'}
    },
    {
        path: '/article/:id',
        name: 'article',
        component: () => import('../views/Articles.vue'),
        meta: { title: '文章'}
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue'),
        meta: { title: '登录'}
    },
    { path: '*', redirect: '/'}
]
const scrollBehavior = function (to, from, savedPosition) {
    // savedPosition 会在你使用浏览器前进或后退按钮时候生效
    // 这个跟你使用 router.go() 或 router.back() 效果一致
    // 这也是为什么我在 tab 栏结构中放入了一个 点击回退 的按钮
    if (savedPosition) {
        return savedPosition
    } else {
        // 如果不是通过上述行为切换组件，就会让页面回到顶部
        return {x: 0, y: 0}
    }
}
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior
})
router.beforeEach((to, from, next) => {
    let title = 'Gblog'
    if (to.meta){
        if (to.meta.params){
            title = `${to.meta.title}:${to.params[to.meta.params] || ''} - ${title}`
        }else {
            title = `${to.meta.title} - ${title}`
        }
    }
    document.title = title
    if (to.name !== 'login' && (to.path !== from.path)) {
        store.dispatch('setLoading', true);
    }
    next();
})
router.afterEach((to, from) => {
    // 最多延迟 关闭 loading
    setTimeout(() => {
        store.dispatch('setLoading', false);
    }, 2000)
})
export default router

<template>
    <transition @after-leave="handleAfterLeave">
        <div class="mask" v-show="show" @click.stop="clickMask">
            <div class="layer-box" :style="style" @click.stop="">
                <div class="layer-head">
                    <span>{{title}}</span>
                    <div class="close-btn" @click.stop="close">×</div>
                </div>
                <div class="layer-body">
                    <template v-if="type===1 || type==='1'">
                        <div class="auth">
                            <div class="auth-types">
                                <div v-for="item in authType" :key="item.type" @click="authHandle(item.type)" :title="item.title" :style="{'background-color': item.bgColor}">
                                    <a :href="'http://127.0.0.1:8080/auth/'+item.type" target="_blank"><i class="iconfont" :class="item.icon"></i></a>
                                </div>
                            </div>
                            <div class="auth-title">快速登录</div>
                        </div>
                    </template>
                    <template v-else><iframe class="layer-frame" :src="url"></iframe></template>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    const defaultOptions = {
        type: 1,
        title: '登录',
        width: 400,
        height: 300,
        onClose: null
    }
    export default {
        name: "layer",
        data() {
            return{
                show: false,
                type: 2,
                title: '',
                width: 500,
                height: 300,
                url: '',
                onClose: null,
                authType: [
                    {type: 'qq', bgColor: '#1AB6FF', icon: 'iconqq', title: 'QQ'},
                    {type: 'gitee', bgColor: '#d81e06', icon: 'icongitee', title: 'Gitee'},
                    {type: 'github', bgColor: '#6d7792', icon: 'icongithub1', title: 'GitHub'}
                ]
            }
        },
        computed: {
            style() {
                return {
                    'width': `${this.width}px`,
                    'height': `${this.height}px`
                }
            }
        },
        watch: {
            show:{
                immediate: true,
                handler: (n) => {
                    let htmlBodyElement = document.querySelector('body');
                    htmlBodyElement.style.overflowY = n ? 'hidden' : 'auto';
                }
            }
        },
        methods: {
            authHandle(type) {
                this.$store.commit('SET_AUTH_TYPE', type)
            },
            close() {
                this.show = false
                if (typeof this.onClose === 'function') {
                    this.onClose(this);
                }
            },
            clickMask() {
                this.close()
            },
            handleAfterLeave() {
                this.$destroy(true);
                this.$el.parentNode.removeChild(this.$el);
            }
        },
        beforeDestroy() {
            this.data = Object.assign(this.$data,defaultOptions)
        }
    }
</script>

<style scoped lang="less">
    .mask{
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.3);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .layer-box{
        background-color: #F9F9F9;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);;
        .layer-head{
            height: 30px;
            line-height: 30px;
            padding-left: 20px;
            /*background-color: #F3F3F3;*/
            .close-btn{
                width: 30px;
                background-color: red;
                color: #FFFFFF;
                line-height: 30px;
                text-align: center;
                float: right;
                cursor: default;
                opacity: 0.7;
                &:hover{
                    opacity: 1;
                }
            }
        }
        .layer-body{
            height: calc(100% - 30px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            .auth{
                height: 60px;
                .auth-types{
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    & > div{
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        text-align: center;
                        line-height: 40px;
                        color: #FFFFFF;
                    }
                }
                .auth-title{
                    padding: 0 20px 0;
                    margin: 30px 0;
                    line-height: 1px;
                    border-left: 100px solid #ddd;
                    border-right: 100px solid #ddd;
                    text-align: center;
                }
            }
            .layer-frame{
                width: 100%;
                height: 100%;
            }
        }
    }
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import {getTimeInterval} from '../utils/index'
import {fetchSocial,fetchSiteInfo,auth,logout} from '@/api'
import { getToken, setToken, removeToken , setAuthType, getAuthType, getUser, setUser} from '@/utils/auth'

Vue.use(Vuex)
let timer = null;
const state = {
    loading: false,
    runTimeInterval: '--',
    socials: '',
    websiteInfo: '',
    token: getToken(),
    userInfo: getUser(),
    authType: getAuthType()
}
const mutations = {
    SET_LOADING: (state, v) => {
        state.loading = v;
    },
    SET_SOCIALS: (state, v) => {
        state.socials = v;
    },
    SET_SITE_INFO: (state, v) =>{
      state.websiteInfo = v;
    },
    SET_RUNTIME_INTERVAL: (state,runAt) => {
        if (!timer || !state.runTimeInterval) {
            clearInterval(timer)
            timer = setInterval(() => {
                state.runTimeInterval = getTimeInterval(runAt);
            }, 1000);
        }
    },
    SET_AUTH_TYPE: (state,type) => {
        state.authType = type
    },
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo;
    }
}
const actions = {
    setLoading: ({commit}, v) => {
        commit('SET_LOADING', v);
    },
    getSiteInfo: ({commit,state}) =>{
        return new Promise(resolve => {
            if (state.websiteInfo){
                resolve(state.websiteInfo)
            }else {
                fetchSiteInfo().then(res => {
                    let data = res.data || {}
                    commit('SET_SITE_INFO',data);
                    commit('SET_RUNTIME_INTERVAL',data.runAt);
                    resolve(data);
                }).catch(err => {
                    resolve({});
                })
            }
        })
    },
    getSocials: ({commit,state}) =>{
        return new Promise((resolve => {
            if (state.socials){
                resolve(state.socials)
            } else {
                fetchSocial().then(res =>{
                    let data = res.data || []
                    commit('SET_SOCIALS',data);
                    resolve(data);
                }).catch(err =>{
                    resolve([]);
                })
            }
        }))
    },
    setAuthType: ({commit},type) => {
        setAuthType(type)
        commit('SET_AUTH_TYPE',type);
    },
    loginAuth: ({commit,state},params) => {
        return new Promise((resolve, reject) => {
            if (!params.type) {
                reject('无效认证')
            } else {
                auth(params).then(res => {
                    commit('SET_TOKEN',res.data.token);
                    commit('SET_USER_INFO',res.data);
                    setToken(res.data.token)
                    setUser(res.data)
                    resolve(res.data)
                }).catch(err => {
                    reject(err)
                })
            }
        })
    },
    logout: ({commit}) => {
        return new Promise((resolve, reject) => {
            logout().then(() => {
                commit('SET_TOKEN','');
                commit('SET_USER_INFO','');
                removeToken()
                resolve()
            }).catch(err => {
                reject(err)
            })
        })
    }
}
const getters = {
    loading: state => state.loading,
    runTimeInterval: state => state.runTimeInterval,
    notice: state => state.websiteInfo?state.websiteInfo.notice:'',
    token: state => state.token,
    socials: state => state.socials,
    websiteInfo: state => state.websiteInfo,
    userInfo: state => state.userInfo
}
export default new Vuex.Store({
    state,
    mutations,
    actions,
    modules: {},
    getters
})
